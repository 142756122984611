
.ResumeAppBar-title {
    flex: 1;
}

.ResumeAppBar {
    background: transparent !important;
    box-shadow: none !important;
}

.ResumeAppBar a {
    color: white;
}

.ResumeAppBar a:hover {
    text-decoration: none;
    background-color: rgba(255, 255, 255, 0.08); /*255, 255, 255, 0.08*/
}


.ResumeAppBar-header {
    justify-content: flex-end;
}
.social-icon{
  margin-left: 0.7rem;
  padding-right:0.7rem;
  font-size: 20px;
}
.social-icon a:hover {
    text-decoration: none;
    background-color: rgba(255, 255, 255,0.08);
}

@media only screen and (max-width: 800px) {
    .ResumeAppBar-header {
        flex-wrap: wrap-reverse;
        justify-content: center;
    }

    .ResumeAppBar-title {
        display: none !important;
    }
}
